<template>
  <v-container fill-height fluid ma-0 pa-0 :style="backgroundStyle">
    <v-row align="center" justify="center">
      <v-col>
        <v-card
          class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
          max-width="100%"
          outlined
        >
          <v-row align="center" justify="center">
            <v-col>
              <p class="secondary--text font-weight-bold text-h1 text-center">404</p>
              <p class="mb-0 secondary--text font-weight-bold text-h5 text-center overline">Page not found</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({}),
  methods: {
    ...mapMutations({
      setBackground: 'sitemap/setBackground',
    }),
  },
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
    }),
  },
  created() {
    this.setBackground()
  }
}
</script>
